<template>
    <div class="px-3 py-2">
        <v-form
            ref="form"
            lazy-validation
        >
        <b-sidebar id="add_customer" aria-labelledby="sidebar-no-header-title" width="900px" no-header backdrop style="direction:ltr" right title="اضافة عميل" shadow >
        <template #default="{ hide }">
            <div class="m-1 text-start" style="direction:rtl">
                <div v-if="$store.state.group_id != 9" style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                <span>{{formTitle}}</span>
                </div>
                <div v-else>
                    &nbsp;
                </div>
                <div @click="hide" id="hidaddcus" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
                <span>{{lang.close}}</span>
            </div>
        </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <div class="tobbuttom" v-if="$store.state.group_id != 9" :style="`direction:${lang.dir};padding:7px;width:100%;text-align:center;background:#efefef;border:1px solid #bbb;`">
                <b-button :style="`border-radius:0;background:transparent;color:#000;border:none;`">{{lang.chose_customer_type}}</b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:.8rem !important;font-weight: bolder;width:100px" id="btn0" :class="`dispFlex weightColor borderRad_`+lang.algin" @click="changeCusType(0)" >{{lang.individual_client}} <i id="icon0"  class="fas fa-arrow-down"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:.8rem !important;font-weight: bolder;width:100px" id="btn1" :class="`dispFlex blackBack borderRad_none`" @click="changeCusType(1)" >{{lang.company_clinet}} <i id="icon1" class="fas fa-arrow-down hideIcon"></i></b-button>
                <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:.8rem !important;font-weight: bolder;width:100px" id="btn2" :class="`dispFlex weightColor borderRad_`+lang.lalgin" @click="changeCusType(2)" >{{lang.government_clinet}} <i id="icon2" class="fas fa-arrow-down hideIcon"></i></b-button>
            </div>
            <!-- <div v-else class=" backBlack" :style="`direction:${lang.dir};padding:7px;width:100%;text-align:center;display:none;`">
                <div><b-button :style="`border-radius:0;background:transparent;color:#fff;border:none;width:100%`">{{lang.customer_type}}</b-button></div>
                <div class="tobbuttom">
                    <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="btn0" :class="`dispFlex btn btn-small weightColor borderRad_`+lang.algin" @click="changeCusType(0)" >{{lang.individual_client}} <i id="icon0"  class="fas fa-arrow-down"></i></b-button>
                    <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="btn1" :class="`dispFlex btn btn-small redColor borderRad_none`" @click="changeCusType(1)" >{{lang.company_clinet}} <i id="icon1" class="fas fa-arrow-down hideIcon"></i></b-button>
                    <b-button style="margin-inline-end:5px;border:2px solid #fff !important;font-size:1.1rem !important;font-weight: bolder;width:150px" id="btn2" :class="`dispFlex btn btn-small weightColor borderRad_`+lang.lalgin" @click="changeCusType(2)" >{{lang.government_clinet}} <i id="icon2" class="fas fa-arrow-down hideIcon"></i></b-button>
                </div>
            </div> -->
            <v-row>
                <v-col md="12">
                    <div class="backBalck text-center p-2 m-1 mt-3" style="border:1px solid #efefef;background:#bbb">{{ lang.customer_info }}</div>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{ lang.chose_from_list }} {{lang.account_client_type}}</label>
                    <div>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            :label="lang.account_client_type"
                            :options="clientTypes"
                            v-model="customers.client_type"
                            />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{ lang.chose_from_list }} {{lang.main_clients}}</label>
                    <div>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            :label="lang.main_clients"
                            :options="mainClients"
                            v-model="customers.main_clients"
                            />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{ lang.chose_from_list }} {{lang.client_source}}</label>
                    <div>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            :label="lang.client_source"
                            :options="clientSource"
                            v-model="customers.client_source"
                            />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="$store.state.licenseType.showCustomerId">
                    <label>{{lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label='lang.customerid'
                        v-model="customers.customerid"
                        :rules="mobileRules"
                        >{{ customers.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="!showCom && !showGov">
                    <label>{{lang.id_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label='lang.id_number'
                        v-model="customers.id_number"
                        
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label='lang.mobile'
                        v-model="customers.mobile"
                        @change="checkCustomer()"
                        
                        :rules="mobileRules"
                        >{{ customers.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="$store.state.licenseType.showAddNumber">
                    <label>{{lang.additional_mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.additional_mobile"
                        v-model="customers.mobile1"
                        @change="checkCustomer()"
                        
                        >{{ customers.mobile1 }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.customer_name"
                        v-model="customers.full_name"
                        :rules="nameRules"
                        >{{ customers.full_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="$store.state.licenseType.showNationality">
                    <label>{{lang.nationality}}</label>
                    <b-form-input
                        
                        class="inborder"
                        :label="lang.nationality"
                        v-model="customers.nationality"
                        :rules="nameRules"
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="12" v-if="showCom">
                    <label>{{lang.company_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.company_name"
                        v-model="customers.company_name"
                        >{{ customers.company_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" v-if="showCom">
                    <label>{{lang.company_vatid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.company_vatid"
                        v-model="customers.company_vatid"
                        
                        >{{ customers.company_vatid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.crt_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.crt_number"
                        v-model="customers.crt_number"
                        
                        >{{ customers.crt_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{lang.city}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.city"
                        v-model="customers.city"
                        
                        >{{ customers.city }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" v-if="$store.state.licenseType.isBill">
                    <label> {{ lang.chose_from_list }} {{lang.industrial}}</label>
                    <div>
                        <b-input-group>
                        <b-form-select
                            class="selborder"
                            :label="lang.industrial"
                            :options="inDustrials"
                            v-model="customers.industrial"
                            />
                            <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </v-col>
                
                <v-col cols="12" md="3" sm="12" v-if="showCom">
                    <label>{{lang.street_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.street_name"
                        v-model="customers.street_name"
                        
                        >{{ customers.street_name }}</b-form-input>
                </v-col>

                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.building_no}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.building_no"
                        v-model="customers.building_no"
                        
                        >{{ customers.building_no }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.branch_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.branch_number"
                        v-model="customers.branch_number"
                        
                        >{{ customers.branch_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.district}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.district"
                        v-model="customers.district"
                        
                        >{{ customers.district }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" v-if="showCom">
                    <label>{{lang.zipcode}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.zipcode"
                        v-model="customers.zipcode"
                        
                        >{{ customers.zipcode }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showCom">
                    <label>{{lang.address}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.address"
                        v-model="customers.address"
                        
                        >{{ customers.address }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{lang.entity_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.entity_name"
                        v-model="customers.entity_name"
                        
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="showGov">
                    <label>{{lang.branch_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="lang.branch_name"
                        v-model="customers.branch_name"
                        
                        ></b-form-input>
                </v-col>
                <v-col cols="12" md="12" sm="12" v-if="$store.state.licenseType.isBill">
                    <v-row>
                        <v-col cols="12" md="3" sm="12">
                            <label> {{ lang.chose_from_list }} {{lang.product}}</label>
                            <b-input-group>
                                <b-form-select
                                class="selborder"
                                v-model="product"
                                :options="getProds"
                                />
                                <b-input-group-append style="margin-right: -20px; margin-top: 5px">
                                <i class="fas fa-arrow-down"></i>
                                </b-input-group-append>
                            </b-input-group>
                        </v-col>
                        
                        <v-col cols="12" md="9" sm="12">
                            <label>{{lang.notes}}</label>
                            <b-form-textarea
                                class="inborder"
                                v-model="notes"
                                value=""
                                required
                            />
                        </v-col>
                    </v-row>
                </v-col>
                
            </v-row>
            <v-row v-if="
                        ($store.state.licenseType.showPeriodMaint || $store.state.licenseType.showOffering ||
                        $store.state.licenseType.showSubscriber || $store.state.licenseType.showBooking) && 
                        $store.state.licenseType.carinfo
                    ">
                <v-col cols="12">
                        <v-col md="12">
                            <div class="backBalck text-center p-2 m-1 mt-3" style="border:1px solid #efefef;background:#bbb">{{ lang.car_info }}</div>
                        </v-col>
                        <v-col md="4">
                            <label> {{ lang.chose_from_list }} {{ lang.carsnumber }}</label>
                            <div>
                                <b-form-select class="inborder" style="width:100%;background:#efefef" @change="growCar()" v-model="car_number" :options="carsNumbers" />
                            </div>
                        </v-col>
                        <v-col cols="12" v-for="(i,index) in car" :key="index">
                            <v-row>
                                <v-col md="4">
                                    <label> {{ lang.chose_from_list }} {{ lang.plate_number }}</label>
                                    <div style="display:flex;flex-direction:row;justify-content:space-between">
                                        <b-form-select class="inborder" style="background:yellow" v-model="car[index].plate.f1" :options="$store.state.plateChars" />
                                        <b-form-select class="inborder" style="background:yellow;border-inline:none !important" v-model="car[index].plate.f2" :options="$store.state.plateChars" />
                                        <b-form-select class="inborder" style="background:yellow;border-inline-end:none !important" v-model="car[index].plate.f3" :options="$store.state.plateChars" />
                                        <b-form-input class="inborder" style="background:yellow" v-model="car[index].plate.text" />
                                    </div>
                                </v-col>
                                <!-- <v-col md="2">
                                    <label>{{ lang.brand }}</label>
                                    <b-form-input class="inborder" v-model="car[index].brand" />
                                </v-col> -->
                                <v-col md="2">
                                    <label>{{ lang.model }}</label>
                                    <b-form-input class="inborder" v-model="car[index].model" />
                                </v-col>
                                <v-col md="2">
                                    <label>{{ lang.car_year }}</label>
                                    <b-form-input class="inborder" v-model="car[index].car_year" />
                                </v-col>
                                <v-col md="2">
                                    <label>{{ lang.color }}</label>
                                    <b-form-input class="inborder" v-model="car[index].color" />
                                </v-col>
                                <v-col md="2">
                                    <label>{{ lang.vin_numebr }}</label>
                                    <b-form-input class="inborder" v-model="car[index].vin" />
                                </v-col>
                            </v-row>
                        </v-col>
                </v-col>
            </v-row>
            <v-row v-if="$store.state.licenseType.showPeriodMaint && customers.customer_type != 3">
                <v-col md="12">
                    <div class="backBlack text-center p-2 m-1 mt-3" style="border:1px solid #efefef;background:#bbb">{{ lang.period_maint }}</div>
                </v-col>
                <v-col md="3">
                    <label> {{lan.choose_mentainance_date}}</label>
                    <div style="display:flex;flex-direction:row;justify-content:space-between;width:100%">
                        <b-form-select class="inborder" style="width:50px;background:#efefef" v-model="period_maint.start_date.day" :options="$store.state.daysList" />
                        <b-form-select class="inborder" style="width:50px;background:#efefef" v-model="period_maint.start_date.month" :options="$store.state.monthsList" />
                        <b-form-select class="inborder" style="width:100px;background:#efefef" v-model="period_maint.start_date.year" :options="$store.state.yearList" />
                    </div>
                    <!-- <b-form-input type="date" class="inborder" v-model="period_maint.start_date" /> -->
                </v-col>
                <v-col md="3">
                    <label>  {{lang.maintenance_date}}</label>
                    <b-form-input type="time" class="inborder" v-model="period_maint.start_time" />
                </v-col>
                <v-col md="3">
                    <label> {{ lang.chose_from_list }} {{lang.customer_type}}</label>
                    <b-form-select class="selborder" v-model="period_maint.work_type" :options="workTypes" />
                </v-col>
                <v-col md="3">
                    <label> {{ lang.chose_from_list }} {{lang.technical}}</label>
                    <b-form-select class="selborder" v-model="period_maint.tech_name" :options="techNames" />
                </v-col>
                <v-col md="12">
                    <label>{{lang.notes}}</label>
                    <b-form-textarea class="inborder" v-model="period_maint.notes"></b-form-textarea>
                </v-col>
            </v-row>
            <v-row v-if="$store.state.licenseType.showSubscriber && customers.customer_type != 3">
                <v-col md="12">
                    <div class="backBalck text-center p-2 m-1 mt-3" style="border:1px solid #efefef;background:#bbb">{{lang.offers_programs}}</div>
                </v-col>
                <v-col md="3">
                    <label> {{ lang.chose_from_list }} {{ lang.start_date }}</label>
                    <div style="display:flex;flex-direction:row;justify-content:space-between;width:100%">
                        <b-form-select class="inborder" style="width:50px;background:#efefef" v-model="progrs.start_date.day" :options="$store.state.daysList" />
                        <b-form-select class="inborder" style="width:50px;background:#efefef" v-model="progrs.start_date.month" :options="$store.state.monthsList" />
                        <b-form-select class="inborder" style="width:100px;background:#efefef" v-model="progrs.start_date.year" :options="$store.state.yearList" />
                    </div>
                    <!-- <b-form-input type="date" class="inborder" v-model="progrs.start_date" /> -->
                </v-col>
                <v-col md="3">
                    <label> {{ lang.chose_from_list }} {{ lang.end_date }}</label>
                    <div style="display:flex;flex-direction:row;justify-content:space-between;width:100%">
                        <b-form-select class="inborder" style="width:50px;background:#efefef" v-model="progrs.expire_date.day" :options="$store.state.daysList" />
                        <b-form-select class="inborder" style="width:50px;background:#efefef" v-model="progrs.expire_date.month" :options="$store.state.monthsList" />
                        <b-form-select class="inborder" style="width:100px;background:#efefef" v-model="progrs.expire_date.year" :options="$store.state.yearList" />
                    </div>
                    <!-- <b-form-input type="date" class="inborder" v-model="progrs.expire_time" /> -->
                </v-col>
                <v-col md="4">
                    <label> {{ lang.chose_from_list }}{{lang.program_name}}</label>

                    <b-form-select class="selborder" v-model="progrs.prog_name" :options="programs" />
                </v-col>
            </v-row>
            <v-row v-if="$store.state.licenseType.showBooking">
                <v-col md="12">
                    <div class="backBalck text-center p-2 m-1 mt-3" style="border:1px solid #efefef;background:#bbb">{{ lang.calendar }}</div>
                </v-col>
                <v-col md="3">
                    <label>{{lang.booking_date}}</label>
                    <div style="display:flex;flex-direction:row;justify-content:space-between;width:100%">
                        <b-form-select class="inborder" style="width:50px;background:#efefef" v-model="calendar.start_date.day" :options="$store.state.daysList" />
                        <b-form-select class="inborder" style="width:50px;background:#efefef" v-model="calendar.start_date.month" :options="$store.state.monthsList" />
                        <b-form-select class="inborder" style="width:100px;background:#efefef" v-model="calendar.start_date.year" :options="$store.state.yearList" />
                    </div>
                    <!-- <b-form-input type="date" class="inborder" v-model="calendar.start_date" /> -->
                </v-col>
                <v-col md="3">
                    <label>{{lang.booking_time}}</label>
                    <b-form-input type="time" class="inborder" v-model="calendar.start_time" />
                </v-col>
                <v-col md="3">
                    <label>{{lang.work_type}}</label>
                    <b-input-group>
                        <b-form-select class="selborder" v-model="calendar.work_type" :options="workTypes" />
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                    
                </v-col>
                <v-col md="3">
                    <label>{{lang.technical}}</label>
                    <b-input-group>
                        <b-form-select class="selborder" v-model="calendar.tech_name" :options="techNames" />
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col md="12">
                    <label>{{lang.notes}}</label>
                    <b-form-textarea class="inborder" v-model="calendar.notes" />
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
            <div class="d-flex text-light align-items-center px-3 py-2">
                <b-button type="button" @click="addCustomer();" variant="success" class="ma-2" style="width:100px;">{{lang.save}}</b-button>
            </div>
      </template>
        </b-sidebar>
        </v-form>
        <vue-snotify></vue-snotify>
      </div>
</template>


<script>
import axios from 'axios';
import {SnotifyPosition} from 'vue-snotify';
export default{
    data() {
        return {
            mainAccounts: [],
            car_number: 1,
            progrs: {
                start_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                sdate: '',
                expire_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                edate: '',
                prog_name: '',
            },
            calendar: {
                start_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                sdate: '',
                start_time: '',
                work_type: '',
                tech_name: '',
                notes: '',
            },
            period_maint: {
                start_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                sdate: '',
                start_time: '',
                work_type: '',
                tech_name: '',
                notes: '',
            },
            car: [{
                plate: {
                    f1: '',
                    f2: '',
                    f3: '',
                    text: ''
                },
                plate_number: '',
                brand: '',
                model: '',
                car_year: '',
                color: '',
                vin: ''
            }],
            cars:{
                plate: {
                    f1: '',
                    f2: '',
                    f3: '',
                    text: ''
                },
                plate_number: '',
                brand: '',
                model: '',
                car_year: '',
                color: '',
                vin: ''
            },
            techs: [],
            works: [],
            programsList: [],
            customers:{
                main_clients: 0,
                client_type: 1,
                id: 0,
                mobile: '',
                mobile1: '',
                full_name: '',
                company_name: '',
                company_vatid: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                customer_type: 2,
                entity_name: '',
                branch_name: '',
                id_number: '',
                industrial: 0,
                client_source: 1,
                crt_number: '',
                nationality: "",
            },
            showCom: true,
            showGov: false,
            optionsS: [],
            inDusts: [],
            sources: [],
            notes: '',
            product: '',
            expected_amount: '',
            editMode: false,
        }
    },
    computed: {
        carNums: function(){
            let t= [];
            for(let i=0;i<this.car_number;i++){
                t.push(i)
            }
            console.log(t);
            return t;
        },
        carsNumbers: function(){
            return [
                {text: 1, value: 1},
                {text: 2, value: 2},
                {text: 3, value: 3},
                {text: 4, value: 4},
                {text: 5, value: 5},
                {text: 6, value: 6}
            ]
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        mainClients: function(){
            let t = [{text: this.lang.without_account, value: 0}];
            for(let i=0;i<this.mainAccounts.length;i++){
                t.push({text: this.mainAccounts[i].customer_name, value: this.mainAccounts[i].id})
            }
            return t;
        },
        clientTypes: function(){
            return [
                {text: this.lang.customer_client, value: 1},
                {text: this.lang.customer_group_client, value: 2}
            ]
        },
        techNames: function(){
            let t = [{text: 'اختر' , value: ''}];
            for(let i=0;i<this.techs.length;i++){
                t.push({text: this.techs[i].employee_name,value: this.techs[i].id})
            }
            return t;
        },
        workTypes: function(){
            let t = [{text: 'اختر' , value: ''}];
            for(let i=0;i<this.works.length;i++){
                t.push({text: this.works[i].name_ar,value: this.works[i].value})
            }
            return t;
        },
        programs: function(){
            let t = [{text: 'اختر' , value: ''}];
            for(let i=0;i<this.programsList.length;i++){
                t.push({text: this.programsList[i].campaign_title,value: this.programsList[i].id})
            }
            return t;
        },
        formTitle: function (){
            if(this.editMode) {
                return this.lang.update_customer;
            }
            return this.lang.add_customer;
        },
        mobileRules: function() {
            return [
                v => !!v || this.lang.required_field,
                v => (v && v.length <= 10) || this.lang.mobil_10_digits,
            ]
        },
        nameRules: function() {
            return [
                v => !!v || this.lang.required_field,
            ]
        },
        inDustrials: function(){
            let t = [];
            for (let i = 0; i < this.inDusts.length; i++) {
                t.push({
                    text: this.inDusts[i].name_ar,
                    value: this.inDusts[i].value,
                });
            }
            return t;
        },
        clientSource: function(){
            let t = [];
            for (let i = 0; i < this.sources.length; i++) {
                t.push({
                    text: this.sources[i].name_ar,
                    value: this.sources[i].value,
                });
            }
            return t;
        },
        getProds: function(){
            let t = [];
            for (let i = 0; i < this.optionsS.length; i++) {
                t.push({
                    text: this.optionsS[i].name_ar,
                    value: this.optionsS[i].value,
                });
            }
            return t;
        }
    }, 
    created(){
        this.getStaticid('convertProducts');
        this.getStaticid('inDustrial');
        this.getStaticid('customerSource');
        this.getSettings();
        this.getAllMain();
    },
    methods: {
        clearAll(){
            this.car_number = 1
            this.progrs = {
                start_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                sdate: '',
                expire_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                edate: '',
                prog_name: '',
            }
            this.calendar = {
                start_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                sdate: '',
                start_time: '',
                work_type: '',
                tech_name: '',
                notes: '',
            }
            this.period_maint = {
                start_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                sdate: '',
                start_time: '',
                work_type: '',
                tech_name: '',
                notes: '',
            }
            this.car = [{
                plate: {
                    f1: '',
                    f2: '',
                    f3: '',
                    text: ''
                },
                plate_number: '',
                brand: '',
                model: '',
                car_year: '',
                color: '',
                vin: ''
            }]
            this.cars = {
                plate: {
                    f1: '',
                    f2: '',
                    f3: '',
                    text: ''
                },
                plate_number: '',
                brand: '',
                model: '',
                car_year: '',
                color: '',
                vin: ''
            }
            this.customers = {
                main_clients: 0,
                client_type: 1,
                id: 0,
                mobile: '',
                mobile1: '',
                full_name: '',
                company_name: '',
                company_vatid: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                customer_type: 1,
                entity_name: '',
                branch_name: '',
                id_number: '',
                industrial: 0,
                client_source: 1,
                crt_number: '',
                nationality: "",
            }
            this.changeCusType(0);
            this.optionsS = []
            this.inDusts = []
            this.sources = []
            this.notes = ''
            this.product = ''
            this.expected_amount = ''
            this.getStaticid('convertProducts');
            this.getStaticid('inDustrial');
            this.getStaticid('customerSource');
            this.getSettings();
        },
        growCar(){
            if(this.car.length > this.car_number){
                let cars = [];
                for(let i=0;i<this.car_number;i++){
                    cars.push(this.car[i]);
                }
                this.car = cars;
            }
            else if(this.car.length < this.car_number){
                let  n = parseInt(this.car_number) - parseInt(this.car.length);
                for(let j=1;j<=n;j++){
                    
                    this.car.push({
                        plate: {
                            f1: '',
                            f2: '',
                            f3: '',
                            text: ''
                        },
                        plate_number: '',
                        brand: '',
                        model: '',
                        vin: '',
                        color: '',
                        car_year: '',

                    });
                }
            }
        },
        getAllMain(){
            const post = new FormData();
            post.append("type", "getMainCustomers");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", 0);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.mainAccounts = response.data.results.data
            });
        },
        getSettings(){
            const post = new FormData();
            post.append("type", "getAddSettings");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", 0);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                this.programsList = response.data.results.progs
                this.techs = response.data.results.techs
                this.works = response.data.results.works
            });
        },
        updateCustomer(item){
            this.customers = {
                main_clients: item.main_clients,
                client_type: item.client_type,
                id: item.id,
                mobile: item.mobile1,
                mobile1: item.mobile2 == "-" ? '' : item.mobile2,
                full_name: item.full_name,
                company_name: item.company_name,
                company_vatid: item.company_vatid,
                building_no: item.building_no,
                street_name: item.street_name,
                branch_number: item.branch_number,
                district: item.district,
                zipcode: item.zipcode,
                city:item.city,
                address:item.address,
                customer_type: item.customer_type,
                entity_name: item.entity_name,
                branch_name: item.branch_name,
                id_number: item.id_number,
                industrial: item.industrial,
                client_source: item.client_source ? item.client_source : 0 ,
                crt_number: item.crt_number,
                nationality: item.nationality,
            }
            this.changeCusType(parseFloat(item.customer_type) - 1)
        },
        reset() {
            this.progrs = {
                start_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                sdate: '',
                expire_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                edate: '',
                prog_name: '',
            }
            this.calendar = {
                start_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                sdate: '',
                start_time: '',
                work_type: '',
                tech_name: '',
                notes: '',
            }
            this.period_maint = {
                start_date: {
                    day: 0,
                    month: 0,
                    year: 0
                },
                sdate: '',
                start_time: '',
                work_type: '',
                tech_name: '',
                notes: '',
            }
            this.car = {
                plate: {
                    f1: '',
                    f2: '',
                    f3: '',
                    text: ''
                },
                plate_number: '',
                brand: '',
                model: '',
                car_year: '',
                color: '',
                vin: '',
            }
            this.customers = {
                main_clients: 0,
                client_type: 1,
                id: 0,
                mobile: '',
                mobile1: '',
                full_name: '',
                company_name: '',
                company_vatid: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
                customer_type: 2,
                entity_name: '',
                branch_name: '',
                id_number: '',
                industrial: 0,
                client_source: 1,
                crt_number: '',
                nationality: "",
            }
        },
        
        getStaticid(id) {
            const post = new FormData();
            post.append("type", "getStaticOptions");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[phrase]", id);
            // post.append("data[phrase]", "convertProducts");
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                // // console.log("i am here",response.data)
                if(id == 'convertProducts')
                    this.optionsS = response.data.results.data;
                else if(id == 'customerSource')
                    this.sources = response.data.results.data
                else if(id == 'inDustrial')
                    this.inDusts = response.data.results.data
                // // console.log("i am here",this.optionsS);
            });
        },
        checkCustomer(){
            const post = new FormData();
            post.append("type", "customerInfoAllM");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth", this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang", this.$cookies.get(this.$store.state.LangCooki));
            post.append("data[id]", this.customers.id);
            post.append("data[mobile]", this.customers.mobile);
            post.append("data[mobile1]", this.customers.mobile1);
            axios.post(this.$store.state.SAMCOTEC.r_path, post).then((response) => {
                if(response && response.data && response.data.results && response.data.results.data && response.data.results.data[0]){
                    this.customers = {
                        main_clients: response.data.results.data[0].main_clients ,
                        client_type: response.data.results.data[0].client_type ,
                        id: response.data.results.data[0].id ,
                        mobile: this.customers.mobile,
                        mobile1: this.customers.mobile1,
                        full_name: response.data.results.data[0].full_name ,
                        company_name: response.data.results.data[0].company_name ,
                        company_vatid: response.data.results.data[0].company_vatid ,
                        building_no: response.data.results.data[0].building_no ,
                        street_name: response.data.results.data[0].street_name,
                        branch_number: response.data.results.data[0].branch_number ,
                        district: response.data.results.data[0].district ,
                        zipcode: response.data.results.data[0].zipcode ,
                        city:response.data.results.data[0].city ,
                        address:response.data.results.data[0].address ,
                        customer_type: response.data.results.data[0].customer_type ,
                        entity_name: response.data.results.data[0].entity_name ,
                        branch_name: response.data.results.data[0].branch_name ,
                        id_number: response.data.results.data[0].id_number ,
                        industrial: response.data.results.data[0].industrial ,
                        client_source: response.data.results.data[0].client_source ,
                        crt_number: response.data.results.data[0].crt_number ,
                        nationality: response.data.results.data[0].nationality ,
                    }
                    this.changeCusType(parseFloat(response.data.results.data[0].customer_type) - parseFloat(1))
                }
            });
        },
        changeCusType(id){
            for(let i=0;i<3;i++){
                document.getElementById(`btn${i}`).classList.remove('blackBack');
                document.getElementById(`btn${i}`).classList.add('weightColor');
                if(i == id){
                    document.getElementById(`btn${i}`).classList.add('blackBack');
                    document.getElementById(`btn${i}`).classList.remove('weightColor');
                    document.getElementById(`icon${id}`).classList.remove('hideIcon')
                }else{
                    document.getElementById(`icon${i}`).classList.add('hideIcon')
                }
            }
            if(id == 0){
                this.customers.customer_type = 1;
                this.showCom = false;
                this.showGov = false;
                
            }else if(id == 1){
                this.customers.customer_type = 2;
                this.showCom = true;
                this.showGov = false;
            }
            else if(id == 2){
                this.customers.customer_type = 3;
                // this.showcamp = true;
                this.showCom = false;
                this.showGov = true;
            }
            // this.changeCtype();
        },
        addCustomer(){
            let vald = true;
            let message = "";
            if(this.$store.state.licenseType.fullMobil){
                if(this.customers.mobile == '' || this.customers.mobile.length < 5 || this.customers.mobile.substring(0,2) != "05"){
                    vald = false;
                    message = this.lang.mobile_info_required_fullmobile;
                }
            }else if(this.customers.mobile == '' || this.customers.mobile.length < 4){
                vald = false;
                message = this.lang.mobile_info_required;
            }
            
            else if(this.customers.full_name == ''  && vald == true){
                vald = false;
                message = this.lang.name_info_required;
            }
            else if((this.customers.customer_type == 2 || this.customers.customer_type == 3) && vald == true){
                if(this.customers.company_name == '' && this.customers.customer_type == 2){
                    vald = true;
                    // message = this.lang.customer_company_info_required;
                    this.customers.company_name = this.customers.full_name;
                }
                if(this.customers.entity_name == '' && this.customers.customer_type == 3){
                    vald = true;
                    // message = this.lang.customer_company_info_required;
                    this.customers.entity_name = this.customers.full_name;
                }
            }
            for(let i=0;i<this.car.length;i++){
                console.log(this.car)
                if(this.car[i].plate.f1 == '' && this.car[i].plate.f2 == '' && this.car[i].plate.f3 == '' && this.car[i].plate.text == ''){
                    // do nothing
                }else if(vald == true){
                    if(
                        this.car[i].plate.f1 == '' || this.car[i].plate.f2 == '' || this.car[i].plate.f3 == '' || this.car[i].plate.text == '' ||
                        this.car[i].model == '' || this.car[i].car_year == '' || this.car[i].color == ''
                    ){
                        vald = false;
                        message = this.lang.car_info_required;
                    }else{
                        this.car[i].plate_number = this.car[i].plate.f1 + " " + this.car[i].plate.f2 + " " + this.car[i].plate.f3 + " " + this.car[i].plate.text
                    }
                }
            }
            
            if(vald == true){
                if(
                    this.calendar.start_date.day == '' &&
                    this.calendar.start_date.month == '' &&
                    this.calendar.start_date.year == '' &&
                    this.calendar.start_time == '' &&
                    this.calendar.work_type == '' &&
                    this.calendar.tech_name == '' &&
                    this.calendar.notes == ''
                ){
                    //do nothing
                }else if(
                    this.calendar.start_date.day == '' ||
                    this.calendar.start_date.month == '' ||
                    this.calendar.start_date.year == '' ||
                    this.calendar.start_time == '' ||
                    this.calendar.work_type == '' ||
                    this.calendar.tech_name == '' ||
                    this.calendar.notes == ''
                ){
                    vald = false;
                    message = this.lang.calnder_all_field_required;
                }
                else{
                    this.calendar.sdate = this.calendar.start_date.year + "-" + this.calendar.start_date.month + "-" + this.calendar.start_date.day
                }
            }

            if(vald == true){
                if(
                    this.period_maint.start_date.day == '' &&
                    this.period_maint.start_date.month == '' &&
                    this.period_maint.start_date.year == '' &&
                    this.period_maint.start_time == '' &&
                    this.period_maint.work_type == '' &&
                    this.period_maint.tech_name == '' &&
                    this.period_maint.notes == ''
                ){
                    //do nothing
                }else if(
                    this.period_maint.start_date.day == '' ||
                    this.period_maint.start_date.month == '' ||
                    this.period_maint.start_date.year == '' ||
                    this.period_maint.start_time == '' ||
                    this.period_maint.work_type == '' ||
                    this.period_maint.tech_name == '' ||
                    this.period_maint.notes == ''
                ){
                    vald = false;
                    message = this.lang.period_maint_all_field_required;
                }
                else{
                    this.period_maint.sdate = this.period_maint.start_date.year + "-" + this.period_maint.start_date.month + "-" + this.period_maint.start_date.day
                }
            }
            if(vald == true){
                if(
                    this.progrs.start_date.day == '' &&
                    this.progrs.start_date.month == '' &&
                    this.progrs.start_date.year == '' &&
                    this.progrs.expire_date.day == '' &&
                    this.progrs.expire_date.month == '' &&
                    this.progrs.expire_date.year == '' &&
                    this.progrs.prog_name == '' 
                ){
                    //do nothing
                }else if(
                    this.progrs.start_date.day == '' ||
                    this.progrs.start_date.month == '' ||
                    this.progrs.start_date.year == '' ||
                    this.progrs.expire_date.day == '' ||
                    this.progrs.expire_date.month == '' ||
                    this.progrs.expire_date.year == '' ||
                    this.progrs.prog_name == '' 
                ){
                    vald = false;
                    message = this.lang.progrs_all_field_required;
                }
                else{
                    this.progrs.sdate = this.progrs.start_date.year + "-" + this.progrs.start_date.month + "-" + this.progrs.start_date.day
                    this.progrs.edate = this.progrs.expire_date.year + "-" + this.progrs.expire_date.month + "-" + this.progrs.expire_date.day
                }
            }

            if(vald == false){
                this.$snotify.error(message, this.lang.alert, {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type" , "addCustomer");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            Object.keys(this.customers).forEach(key => {
                post.append("data["+key+"]",this.customers[key])
            });
            post.append("data[calendar]",JSON.stringify(this.calendar))
            post.append("data[period_maint]",JSON.stringify(this.period_maint))
            post.append("data[progs]",JSON.stringify(this.progrs))
            post.append("data[car]",JSON.stringify(this.car))
            post.append("data[notes]",this.notes)
            post.append("data[product]",this.product)
            post.append("data[expected_amount]",this.expected_amount)
            
            axios.post(
                this.$SAMCOTEC.r_path , post
            ).then((response) => {
                var res = response.data;
                if(res.error.number == 200){
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    message = this.lang.customer_addedd;
                    this.$snotify.error(message, this.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    
                    this.customers = {
                        main_clients: 0,
                        client_type: 1,
                        id: 0,
                        mobile: '',
                        mobile1: '',
                        full_name: '',
                        company_name: '',
                        company_vatid: '',
                        building_no: '',
                        street_name: '',
                        branch_number: '',
                        district: '',
                        zipcode: '',
                        city:'',
                        address:'',
                        customer_type: 2,
                        entity_name: '',
                        branch_name: '',
                        id_number: '',
                        industrial: 0,
                        client_source: 1,
                        crt_number: '',
                        nationality: "",
                    }
                    document.getElementById('hidaddcus').click();
                    this.$parent.getClients();
                }else{
                    message = res.error.message;
                    this.$snotify.error(message, this.lang.alert, {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    
                    if(res.error.number == 202){
                        this.$parent.customer.mobile = this.customers.mobile;
                        this.$parent.customer.full_name = this.customers.full_name;
                        
                        document.getElementById("add_customer").style.display = "none"
                    }
                }
            })
        }
    },
}
</script>
<style scoped>
.hideIcon {
    display:none;
}
.borderRad_right,  .borderRad_left, .borderRad_none{
    padding:2px 5px !important;
}
</style>